a {
    cursor: pointer;
}

button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

button:focus {
    outline: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

#svg {
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: draw 4s ease-in-out forwards;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
