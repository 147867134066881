@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes blink {
  0%, 49.99% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
  
#underscore {
  animation: blink 1.3s infinite;
}

.hidden-expertise {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.hidden-expertise.visible {
  opacity: 1;
  transform: translateY(0);
}

.scroll-margin-top {
  scroll-margin-top: 100px;
}

.scroll-padding-top {
  scroll-padding-top: 100px;
}
#navbarContainer {
  position: sticky;
  top: 0;
  background-color: rgba(15, 23, 42, 0);
  backdrop-filter: blur(2px);
  z-index: 50;
}

#linksContainer div {
  margin-right: 8px;
  transition: opacity 0.2s ease-in-out;
}

#linksContainer div:hover {
  opacity: 0.7;
  cursor: pointer;
}
